import React from 'react';

import './LearnMore.scss';
import Arrow from "./../../../images/arrow.svg";

const LearnMore = ({ text }) => (
  <div className="learn-more-container">
    <div className="learn-more-label">
      {text}
    </div>
    <div className="arrow-icon">
      <img src={Arrow} />
    </div>
  </div>
);

export default LearnMore;
