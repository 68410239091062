import React from 'react';

import './ListOfBlogs.scss';
import { Container } from "@material-ui/core"
import Blog from "../../shared/Blog/Blog"
import Pagination from "../../shared/Pagination"

const ListOfBlogs = () => {
  return (
    <div className="list-of-blogs-section">
      <Container>
        <div className="list-of-blogs-content">
          <Blog alignStyle="horizontal" showContinueReading />
          <Blog alignStyle="horizontal" showContinueReading />
        </div>
        <div className="list-of-blogs-pagination">
        </div>
      </Container>
    </div>
  );
};

export default ListOfBlogs;
