import React from "react"

import Layout from "src/components/Layout"
import SEO from "src/components/seo"
import TopBlog from "src/components/Blogs/TopBlog/TopBlog"
import BlogsCategories from "src/components/Blogs/BlogsCategories/BlogsCategories"
import PopularBlogs from "src/components/Blogs/PopularBlogs/PopularBlogs"
import ListOfBlogs from "src/components/Blogs/ListOfBlogs/ListOfBlogs"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle} currentPage="blogs">
      <SEO title="All posts" />

      <TopBlog />
      {/*<BlogsCategories />*/}
      <PopularBlogs />
      <ListOfBlogs />

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
