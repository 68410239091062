import React from "react"

import "./TopBlog.scss"
import { Container } from "@material-ui/core"
import TopImage from "./../../../images/blogs/blog-top.png"
import Button from "../../shared/Button/Button"
import Avatar from "../../shared/Avatar"

const TopBlog = ({ blog }) => {
  return (
    <div className="top-blog-section">
      <Container>
        <div className="top-blog-content">
          <div className="details">
            <div className="details-header">
              Pushed by Pandemic, Amazon Goes on a Hiring Spree Without Equal
            </div>
            <div className="details-date">June 8, 2019</div>
            <div className="top-blog-owner">
              <Avatar
                avatar={() => <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZuewj48pfOOa1Z6c4pK-SnqcLhFWFu3-Fxg&usqp=CAU"} /> }
                name="Allison Fox"
                position="CEO at Foothill"
              />
            </div>
            <div className="top-blog-actions">
              <div className="continue-reading">
                <Button label="Continue reading" theme="primary" />
              </div>
              <div className="$hare-with">Share</div>
            </div>
          </div>

          <div className="top-blog-thumbnail">
            <img src={TopImage} alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TopBlog
