import React from "react"

import "./Avatar.scss"

const Avatar = ({ avatar: Avatar, name, position }) => (
  <div className="avatar-container">
    <div className="avatar-img-container">
      <Avatar />
    </div>
    <div className="avatar-name-container">
      <div className="avatar-name">{name}</div>
      <div className="avatar-description">{position}</div>
    </div>
  </div>
)

export default Avatar
