import React from 'react';

import './PopularBlogs.scss';
import { Container } from "@material-ui/core"
import Blog from "../../shared/Blog/Blog"

const PopularBlogs = () => {
  return (
    <div className="popular-blogs-section">
      <Container maxWidth="xl">
        <div className="popular-blogs-content">
          <div className="popular-blogs-title">
            Popular Posts
          </div>
          <div className="popular-blogs-list">
            <Blog alignStyle="vertical" />
            <Blog alignStyle="vertical" />
          </div>
        </div>
      </Container>
    </div>
  )
};

export default PopularBlogs;
