import React from 'react';

import './Blog.scss';
import LearnMore from "../LearnMore"

const Blog = ({ alignStyle, showContinueReading = false}) => {
  return (
    <div className={`blog-container ${alignStyle}-blog`}>
      <div className="blog-thumbnail">
        <img src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8YmxvZ3xlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&w=1000&q=80" />
      </div>
      <div className="blog-details">
        <div className="blog-title">
          Article About Tech Industry
        </div>
        <div className="blog-metadata">
          May 13, 2019 by
          <div className="blog-writer">
            &nbsp;Allison Fox
          </div>
        </div>
        <div className="blog-headlines">
          Torquem detraxit hosti et quidem faciunt, ut et negent satis esse appetendum, alterum. Si sine causa? quae fuerit causa, mox videro; interea hoc epicurus in liberos.
        </div>
        {
          showContinueReading && (
            <div className="continue-reading">
              <LearnMore text="Continue reading" />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Blog;
